import { formatDate } from 'src/utils/datetime.js';

export function canPassAgain(date) {
  const nextAttemptDate = new Date(date);
  nextAttemptDate.setDate(nextAttemptDate.getDate() + 30);
  return nextAttemptDate < new Date();
}

export function nextAttemptText(date) {
  const nextAttemptDate = new Date(date);
  nextAttemptDate.setDate(nextAttemptDate.getDate() + 30);
  const formatNextAttemptDate = formatDate(nextAttemptDate);
  return `Вы сможете заново пройти тест ${formatNextAttemptDate}`;
}

export function finishDate(date) {
  const formatFinishDate = formatDate(date);
  return `Тест от ${formatFinishDate}`;
}
