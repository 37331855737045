<template>
  <div v-if="shouldShowBanner" class="skill-banner">
    <a
      ref="skillsBanner"
      class="skill-banner__link"
      target="_blank"
      :href="quizLink"
    >
      <div class="skill-banner__title">
        Оцените свой&nbsp;навык «{{ skillsBanner.skillName }}»
      </div>
      <div class="skill-banner__description">
        Пройдите тест и получите отметку в профиле, чтобы стать заметнее среди
        других специалистов.
      </div>
      <div class="skill-banner__school">
        <img
          class="skill-banner__school-image"
          src="~images/IISAD_logo.svg"
          width="24"
          height="24"
          alt=""
        />
        <div class="skill-banner__school-info">
          <div class="skill-banner__school-name">IISAD</div>
          <div class="skill-banner__school-label">Автор теста</div>
        </div>
      </div>
      <img
        class="skill-banner__image"
        src="~images/skills_banner_bg_sa.png"
        alt=""
      />
    </a>
    <button
      v-if="canClose"
      title="Закрыть баннер"
      class="skill-banner__close"
      type="button"
      @click="closeBanner"
    >
      <SvgIcon icon="cross" size="12" color="muted" />
    </button>
  </div>
</template>

<script>
import { SkillTestResource } from 'src/services/skill-test.js';

import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

export default {
  name: 'SkillBanner',
  components: { SvgIcon },
  props: {
    skillsBanner: {
      type: Object,
      required: true,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      shouldShowBanner: true,
    };
  },
  methods: {
    closeBanner() {
      this.shouldShowBanner = false;
      SkillTestResource.closeSkillBanner(this.quizId);
    },
  },
  computed: {
    quizId() {
      return this.skillsBanner.quizId;
    },
    quizLink() {
      return `/quiz/${this.quizId}`;
    },
  },
};
</script>
