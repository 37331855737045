<template>
  <div
    class="skills-list-show"
    :class="bem('skills-list-show', { profile: isProfile })"
  >
    <SkillsListShowItem
      v-for="skill in skills"
      :key="skill.href + skill.id"
      :user-login="userLogin"
      :has-html="hasHtml"
      :skill="skill"
      :is-profile="isProfile"
      :is-owner="isOwner"
      :is-curator="isCurator"
      :is-guest="isGuest"
      :show-suggest="showSuggest"
      :blocked-status="blockedStatus"
      :disable-track="disableTrack"
    />
  </div>
</template>

<script>
import { bem } from 'src/utils/bem.js';

import SkillsListShowItem from 'src/components/SkillsListShowItem/SkillsListShowItem.vue';

export default {
  name: 'SkillsListShow',
  components: {
    SkillsListShowItem,
  },
  props: {
    skills: {
      type: Array,
      default: () => [],
    },
    isProfile: {
      type: Boolean,
      default: false,
    },
    userLogin: {
      type: String,
      required: true,
    },
    hasHtml: {
      type: Boolean,
      default: false,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    isCurator: {
      type: Boolean,
      default: false,
    },
    isGuest: {
      type: Boolean,
      default: false,
    },
    showSuggest: {
      type: Boolean,
      default: false,
    },
    blockedStatus: {
      type: String,
      default: null,
    },
    disableTrack: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    bem,
  },
};
</script>
