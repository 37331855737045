<template>
  <DecoratedResultModal
    @close="$emit('close')"
    appearance="success"
    title="Отправили тест кандидату"
  >
    <template #default>
      <p>
        Пришлём уведомление на Хабр Карьере и на почту, как только специалист
        его пройдёт.
      </p>
    </template>

    <template #actions>
      <ButtonGroup appearance="inline">
        <BasicButton
          type="button"
          appearance="primary"
          size="l"
          :href="conversationHref"
        >
          К диалогу
        </BasicButton>
        <BasicButton
          type="button"
          appearance="secondary"
          size="l"
          @click="$emit('close')"
        >
          Закрыть
        </BasicButton>
      </ButtonGroup>
    </template>
  </DecoratedResultModal>
</template>

<script>
import { bem } from 'src/utils/bem.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import ButtonGroup from 'src/components/ButtonGroup/ButtonGroup.vue';
import DecoratedResultModal from 'src/components/DecoratedResultModal/DecoratedResultModal.vue';

export default {
  name: 'SuccessSentTestModal',
  components: {
    BasicButton,
    ButtonGroup,
    DecoratedResultModal,
  },
  props: {
    conversationHref: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  methods: {
    bem,
  },
};
</script>
