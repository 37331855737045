<template>
  <div v-if="shouldShowBanner" class="skill-test-banner">
    <div class="skill-test-banner__info">
      <div class="skill-test-banner__title" v-html="titleText" />
      <div class="skill-test-banner__description">
        {{ descriptionText }}
      </div>
      <img
        class="skill-test-banner__label"
        src="~images/skills_test_banner_label.png"
        alt=""
      />
      <img
        class="skill-test-banner__image"
        src="~images/skills_banner_bg.png"
        alt=""
      />
    </div>
    <button
      title="Закрыть баннер"
      class="skill-test-banner__close"
      type="button"
      @click="closeBanner"
    >
      <SvgIcon icon="cross" size="12" color="muted" />
    </button>
  </div>
</template>

<script>
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

import { TestsResource } from 'src/services/tests';

export default {
  name: 'SkillTestBanner',
  components: { SvgIcon },
  props: {
    isOwner: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      shouldShowBanner: true,
    };
  },
  methods: {
    closeBanner() {
      this.shouldShowBanner = false;
      let role;
      if (!this.isOwner) {
        role = 'curator';
      }
      TestsResource.closeSkillTestBanner(role);
    },
  },
  computed: {
    titleText() {
      if (this.isOwner) return 'Подтвердите свои&nbsp;знания';
      return 'Проверьте навыки&nbsp;кандидата';
    },
    descriptionText() {
      if (this.isOwner)
        return 'Пройдите тест по специализации или навыку и выделитесь с отметкой среди специалистов';
      return 'Предложите пройти тест по специализации или навыку и получите подробный отчёт с результатами';
    },
  },
};
</script>
