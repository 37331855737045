<template>
  <TestItem
    appearance="sent"
    :has-visibility-settings="false"
    :provider="test.provider"
  >
    <template #mainBlock>
      <div v-if="showTitle" class="test-item__name">{{ test.title }}</div>
      <div v-if="paidAttempts && isOwner" class="test-item__available">
        <div>Прохождение теста</div>
        <div v-if="test.availableAttempts && test.availableAttempts > 0">
          Доступно: {{ test.availableAttempts }}
        </div>
      </div>
      <BasicButton
        v-if="isOwner"
        type="button"
        :href="test.link"
        appearance="secondary"
        size="m"
        full-width
        class="test-item__button"
        @click="
          $emit('sendTest', { test, params: paidAttempts ? '_0_300' : '' })
        "
      >
        <template v-if="paidAttempts">
          <span>Пройти за 0 ₽ </span>
          <span class="test-item__price"> 300 ₽</span>
        </template>
        <template v-else> Пройти </template>
      </BasicButton>
      <div v-else-if="test.sentAt" class="test-item__date">
        {{ sentDate }}
      </div>
      <component
        v-else
        :is="isBlocked ? 'BasicTooltip' : 'div'"
        :content="tooltipText"
        :max-width="225"
        placement="top"
        theme="dark"
      >
        <BasicButton
          type="button"
          appearance="secondary"
          size="m"
          :disabled="isBlocked"
          full-width
          class="test-item__button test-item__button--send"
          @click="$emit('sendTest', { test })"
        >
          {{ 'Предложить пройти' }}
        </BasicButton>
      </component>
    </template>
  </TestItem>
</template>

<script>
import { formatDate } from 'src/utils/datetime.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
import TestItem from 'src/components/tests/cards/TestItem/TestItem.vue';

export default {
  name: 'TestItemSent',
  components: {
    TestItem,
    BasicButton,
    BasicTooltip,
  },
  inject: ['mediaQueries'],
  props: {
    test: {
      type: Object,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    paidAttempts: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    blockedStatus: {
      type: String,
      default: null,
    },
  },
  emits: ['sendTest'],
  computed: {
    sentDate() {
      if (!this.test.sentAt) return '';
      const formatSentDate = formatDate(this.test.sentAt);
      return `Отправлен ${formatSentDate}`;
    },
    isBlocked() {
      return this.tooltipText !== null;
    },
    tooltipText() {
      switch (this.blockedStatus) {
        case 'blocked_by_user':
          return 'Вы не можете попросить пройти тест этого пользователя, так как он вас заблокировал';
        case 'blocked_by_you':
          return 'Вы не можете попросить пройти тест этого пользователя, так как вы его заблокировали';
        default:
          return null;
      }
    },
  },
};
</script>
