<template>
  <ModalWindow
    appearance="new"
    :title="testResult.title"
    is-content-scrollable
    @close="$emit('close')"
  >
    <TestResultItem :test="testResult" :is-owner="isOwner" />
    <template #footer>
      <div class="specs-test-result-modal__footer">
        <BasicButton appearance="secondary" size="l" @click="$emit('close')">
          Закрыть
        </BasicButton>
      </div>
    </template>
  </ModalWindow>
</template>

<script>
import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';
import TestResultItem from 'src/components/tests/cards/TestResultItem/TestResultItem.vue';

export default {
  name: 'SpecsTestResultModal',
  components: {
    TestResultItem,
    ModalWindow,
    BasicButton,
  },
  props: {
    testResult: {
      type: Object,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
};
</script>
