<template>
  <component
    :is="getComponent(test.status)"
    :is-owner="isOwner"
    :paid-attempts="paidAttempts"
    :test="test"
    :has-report="hasReport"
    @sendTest="$emit('sendTest', $event)"
  />
</template>

<script>
import TestItemExpired from 'src/components/tests/cards/TestItemExpired/TestItemExpired.vue';
import TestItemFailed from 'src/components/tests/cards/TestItemFailed/TestItemFailed.vue';
import TestItemPassed from 'src/components/tests/cards/TestItemPassed/TestItemPassed.vue';
import TestItemSent from 'src/components/tests/cards/TestItemSent/TestItemSent.vue';

const components = {
  passed: TestItemPassed,
  expired: TestItemExpired,
  sent: TestItemSent,
  failed: TestItemFailed,
};
export default {
  name: 'TestResultItem',
  components: {
    TestItemPassed,
    TestItemExpired,
    TestItemSent,
    TestItemFailed,
  },
  props: {
    test: {
      type: Object,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    hasReport: {
      type: Boolean,
      default: false,
    },
    paidAttempts: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['sendTest'],
  methods: {
    getComponent(status) {
      return components[status];
    },
  },
};
</script>
