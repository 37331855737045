<template>
  <div class="skills-list-show-item__wrapper">
    <WithSpinnerPlaceholder :loading="isLoading">
      <div
        v-if="!currentSkill.approvedSkill"
        class="skills-list-show-item"
        :class="bem('skills-list-show-item', { profile: isProfile })"
        @click="openSkillResult()"
      >
        <span v-if="hasHtml" v-html="currentSkill.title" />
        <template v-else> {{ currentSkill.title }} </template>
      </div>
      <BasicTooltip
        v-else
        ref="tooltip"
        content="Навык подтвержден тестом"
        :max-width="280"
        theme="dark"
      >
        <div
          class="skills-list-show-item skills-list-show-item--approved"
          :class="bem('skills-list-show-item', { profile: isProfile })"
          @click="openSkillResult()"
        >
          <span v-if="hasHtml" v-html="currentSkill.title" />
          <template v-else> {{ currentSkill.title }} </template>
          <SvgIcon
            class="skills-list-show-item__icon"
            icon="check-approved"
            color="muted"
            size="24"
          />
          <span v-if="currentSkill.grade" class="skills-list-show-item__grade">
            {{ currentSkill.grade }}</span
          >
        </div>
      </BasicTooltip>
    </WithSpinnerPlaceholder>
    <SkillTestModal
      v-if="showSkillTestsModal"
      :skill="currentSkill"
      :available-tests="availableTests"
      :is-owner="isOwner"
      :is-loading="isRequestTestLoading"
      :blocked-status="blockedStatus"
      @close="showSkillTestsModal = false"
      @sendTest="sendTest"
    />
    <SuccessSentTestModal
      v-if="showTestSentModal"
      @close="showTestSentModal = false"
      :conversation-href="conversationHref"
    />
  </div>
</template>

<script>
import { handleRequestError } from 'src/services/api.js';
import { bem } from 'src/utils/bem.js';

import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';
import SkillTestModal from 'src/components/tests/SkillTestModal/SkillTestModal.vue';
import SuccessSentTestModal from 'src/components/tests/SuccessSentTestModal/SuccessSentTestModal.vue';

import { TestsResource } from 'src/services/tests';

export default {
  name: 'SkillsListShowItem',
  components: {
    SuccessSentTestModal,
    WithSpinnerPlaceholder,
    SkillTestModal,
    SvgIcon,
    BasicTooltip,
  },
  inject: ['visitor'],
  props: {
    skill: {
      type: Object,
      required: true,
    },
    isProfile: {
      type: Boolean,
      default: false,
    },
    userLogin: {
      type: String,
      required: true,
    },
    hasHtml: {
      type: Boolean,
      default: false,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    isCurator: {
      type: Boolean,
      default: false,
    },
    isGuest: {
      type: Boolean,
      default: false,
    },
    showSuggest: {
      type: Boolean,
      default: false,
    },
    blockedStatus: {
      type: String,
      default: null,
    },
    disableTrack: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateSkill'],
  data() {
    return {
      showSkillTestsModal: false,
      showTestSentModal: false,
      availableTests: null,
      isLoading: false,
      isRequestTestLoading: false,
      currentSkill: this.skill,
    };
  },
  methods: {
    bem,
    // eslint-disable-next-line complexity
    async openSkillResult() {
      if (this.isGuest) return;

      this.availableTests = null;
      this.isLoading = true;
      if (
        this.currentSkill.approvedSkill ||
        (!this.disableTrack &&
          (this.isCurator || this.isOwner) &&
          this.showSuggest)
      ) {
        await this.getSkillTest();
      }

      if (this.availableTests?.length) {
        this.showSkillTestsModal = true;
      }
      this.isLoading = false;
      this.$refs.tooltip?.hide();
    },
    async getSkillTest() {
      try {
        const response = await TestsResource.getSkillsTest(
          this.userLogin,
          this.currentSkill.id,
          this.showSuggest,
        );
        const { list, meta } = response.data;
        this.availableTests = list;
        this.updateSkill(meta);
      } catch (error) {
        handleRequestError(error);
      }
    },
    async sendTest({ params }) {
      const request = {
        kind: 'skill',
        title: this.currentSkill.alias,
        user: this.userLogin,
      };
      try {
        this.isRequestTestLoading = true;
        if (this.isOwner) {
          const url = `${window.location.origin}/tests/skills/${this.currentSkill.alias}`;
          window.open(url, '_blank');
          window.helpers.trackEventByName(`test_skill_start${params}`, {
            event_category: `habr_${this.skill.title}`,
          });
        } else {
          await TestsResource.requestTest({ request });
          this.showTestSentModal = true;
          window.helpers.trackEventByName('test_skill_send', {
            event_category: `habr_${this.skill.title}`,
          });
        }
        this.showSkillTestsModal = false;
      } catch (error) {
        handleRequestError(error);
      } finally {
        this.isRequestTestLoading = false;
      }
    },
    updateSkill(meta) {
      if (meta && !this.currentSkill.approvedSkill) {
        this.currentSkill.approvedSkill = meta.approvedSkill;
        this.currentSkill.grade = meta.grade;
        this.currentSkill.passed = meta.passed;
      }
    },
  },
  computed: {
    conversationHref() {
      return `/conversations/${this.userLogin}`;
    },
  },
};
</script>
