<template>
  <TestItem
    appearance="expired"
    :has-visibility-settings="isOwner"
    status-name="Пройден, данные устарели"
    :is-hidden="test.hidden"
    :provider="test.provider"
  >
    <template #mainBlock>
      <div class="test-item__date">{{ finishDate }}</div>
      <div v-if="test.level" class="test-item__level">
        {{ `Уровень «${test.level}»` }}
      </div>
      <div
        v-if="paidAttempts && isOwner && test.availableAttempts > 0"
        class="test-item__available"
      >
        <div>Прохождение теста</div>
        <div v-if="test.availableAttempts">
          Доступно: {{ test.availableAttempts }}
        </div>
      </div>
      <component
        v-if="isOwner"
        :is="canPassAgain ? 'div' : 'BasicTooltip'"
        :content="nextAttemptText"
        placement="top"
        :max-width="240"
        theme="dark"
      >
        <BasicButton
          type="button"
          appearance="secondary"
          size="m"
          :disabled="!canPassAgain"
          full-width
          class="test-item__button"
          @click="
            $emit('sendTest', {
              test,
              params: paidAttempts ? '_again_0_300' : '_again',
            })
          "
        >
          <template v-if="paidAttempts">
            <span> Пройти заново за 0 ₽ </span>
            <span class="test-item__price"> 300 ₽</span>
          </template>
          <template v-else> Пройти заново </template>
        </BasicButton>
      </component>
    </template>

    <template #additionalBlock>
      <TestResultAdditional :test-result="test" :has-report="hasReport" />
    </template>
  </TestItem>
</template>

<script>
import { canPassAgain, finishDate, nextAttemptText } from 'src/utils/tests.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
import TestItem from 'src/components/tests/cards/TestItem/TestItem.vue';
import TestResultAdditional from 'src/components/tests/cards/TestResultAdditional/TestResultAdditional.vue';

export default {
  name: 'TestItemExpired',
  components: {
    TestItem,
    TestResultAdditional,
    BasicButton,
    BasicTooltip,
  },
  inject: ['mediaQueries'],
  props: {
    test: {
      type: Object,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    hasReport: {
      type: Boolean,
      default: false,
    },
    paidAttempts: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['sendTest'],
  computed: {
    finishDate() {
      return finishDate(this.test.finishedAt);
    },
    canPassAgain() {
      return canPassAgain(this.test.finishedAt);
    },
    nextAttemptText() {
      return nextAttemptText(this.test.finishedAt);
    },
  },
};
</script>
