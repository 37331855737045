<template>
  <TransitionFade body-scroll-lock>
    <ModalWindow
      appearance="new"
      :title="skill.title"
      is-content-scrollable
      @close="$emit('close')"
    >
      <WithSpinnerPlaceholder
        v-if="isLoading"
        :loading="isLoading"
        :size="72"
        color="light-blue"
        class="skill-test-modal__placeholder"
      />
      <div v-else class="skill-test-modal">
        <template v-for="test in availableTests">
          <TestItemSent
            v-if="test.provider && test.provider.kind === 'habr' && !test.level"
            :key="test.title"
            :is-owner="isOwner"
            paid-attempts
            :test="test"
            :blocked-status="blockedStatus"
            @sendTest="$emit('sendTest', $event)"
          />
          <TestResultItem
            v-else-if="
              test.provider && test.provider.kind === 'habr' && test.level
            "
            :key="test.title"
            :test="test"
            :has-report="isOwner"
            :is-owner="isOwner"
            :paid-attempts="true"
          />
          <TestItem
            v-else
            :key="test.title"
            appearance="passed"
            :has-visibility-settings="isOwner"
            status-name="Пройден"
            :provider="test.provider"
          >
            <template #mainBlock>
              <div class="skill-test-modal-item__date">
                {{ getFinishDate(test.finishedAt) }}
              </div>
              <div class="skill-test-modal-item__level">
                {{ `Уровень «${test.level}»` }}
              </div>
            </template>
          </TestItem>
        </template>
      </div>
      <template #footer>
        <div class="skill-test-modal__footer">
          <BasicButton appearance="secondary" size="l" @click="$emit('close')">
            Закрыть
          </BasicButton>
        </div>
      </template>
    </ModalWindow>
  </TransitionFade>
</template>

<script>
import { formatDate } from 'src/utils/datetime.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';
import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';
import TestItem from 'src/components/tests/cards/TestItem/TestItem.vue';
import TestItemSent from 'src/components/tests/cards/TestItemSent/TestItemSent.vue';
import TestResultItem from 'src/components/tests/cards/TestResultItem/TestResultItem.vue';
import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';

export default {
  name: 'SkillTestModal',
  components: {
    WithSpinnerPlaceholder,
    TestItem,
    TestResultItem,
    TransitionFade,
    BasicButton,
    ModalWindow,
    TestItemSent,
  },
  props: {
    skill: {
      type: Object,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    availableTests: {
      type: Array,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    blockedStatus: {
      type: String,
      default: null,
    },
  },
  emits: ['close', 'sendTest'],
  methods: {
    getFinishDate(lastResult) {
      const formatFinishDate = formatDate(lastResult);
      return `Тест от ${formatFinishDate}`;
    },
  },
};
</script>
