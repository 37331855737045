<template>
  <WithSpinnerPlaceholder
    :loading="isReportLoading && !hasReport"
    class="test-result-additional"
  >
    <div class="test-result-additional__report">
      <div class="test-result-additional__report-name">Подробный отчет</div>
      <div v-if="!currentHasReport" class="test-result-additional__report-date">
        {{ endOfFreePeriodDate }}
      </div>
    </div>
    <BasicButton
      v-if="!currentHasReport"
      type="button"
      appearance="green-primary"
      size="m"
      full-width
      @click="getReport"
    >
      <span>Получить отчёт за 0 ₽</span>
      <span class="test-result-additional__price">2500 ₽</span>
    </BasicButton>
    <BasicButton
      v-else
      type="button"
      :href="reportLink"
      target="_blank"
      appearance="primary"
      size="m"
      full-width
      @click.native="logDownloadEvent"
    >
      Посмотреть отчёт
    </BasicButton>
  </WithSpinnerPlaceholder>
</template>

<script>
import { handleRequestError } from 'src/services/api.js';
import { bem } from 'src/utils/bem.js';
import { formatDate } from 'src/utils/datetime.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';

import { TestsResource } from 'src/services/tests';

export default {
  name: 'TestResultAdditional',
  components: {
    WithSpinnerPlaceholder,
    BasicButton,
  },
  inject: ['mediaQueries'],
  props: {
    testResult: {
      type: Object,
      required: true,
    },
    hasReport: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      currentHasReport: this.hasReport,
      isReportLoading: false,
      reportLink: null,
    };
  },
  mounted() {
    if (this.hasReport) this.getReport();
  },
  methods: {
    bem,
    async getReport() {
      try {
        this.isReportLoading = true;
        const response = await TestsResource.getTestReport(
          this.testResult.scoringId,
        );
        this.reportLink = response.data.link;
        this.currentHasReport = true;
        window.helpers.trackEvent('take', 'result');
      } catch (error) {
        handleRequestError(error);
      } finally {
        this.isReportLoading = false;
      }
    },
    logDownloadEvent() {
      window.helpers.trackEvent('download', 'result');
    },
  },
  computed: {
    endOfFreePeriodDate() {
      const formatEndOfFreePeriodDate = formatDate(
        this.testResult.endOfFreePeriod,
      );
      return `до ${formatEndOfFreePeriodDate}`;
    },
  },
};
</script>
