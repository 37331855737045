import { apiUrlV1, cancellableRequest } from 'src/services/api.js';

export const TestsResource = {
  getAvailableTests(userAlias, skillAlias) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/scoring/tests`,
      params: {
        login: userAlias,
        skill: skillAlias,
      },
    });
  },
  getUserTests(userAlias) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/scoring`,
      params: {
        user: userAlias,
      },
    });
  },

  /**
   * @param {{
   *   request: {
   *     kind: string,
   *     title: string,
   *     user: string,
   *   }
   * }} params
   */
  requestTest(params) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrlV1}/scoring/request`,
      data: params,
    });
  },
  getSpecsTestResult(testId) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/scoring/${testId}/result`,
    });
  },
  getTestReport(testId) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/scoring/${testId}/report`,
    });
  },
  closeSkillTestBanner(role) {
    return cancellableRequest({
      method: 'patch',
      url: `${apiUrlV1}/scoring/close_banner`,
      params: {
        role,
      },
    });
  },
  getSkillsTest(userAlias, skillId, showSuggest) {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/scoring/skill/${skillId}`,
      params: {
        login: userAlias,
        showSuggest,
      },
    });
  },
};
