<template>
  <div class="test-item" :class="bem('test-item', { status: appearance })">
    <div class="test-item__block">
      <VisibilitySettings
        v-if="hasVisibilitySettings"
        class="test-item__visibility"
        :is-hidden="isHidden"
      />
      <div
        v-if="statusName"
        class="test-item__status"
        :class="
          bem('test-item__status', {
            status: appearance,
          })
        "
      >
        {{ statusName }}
      </div>
      <div class="test-item__provider">
        <img
          :src="provider.logo.src"
          class="test-item__provider-icon"
          :alt="`Логотип компании ${provider.name}`"
        />
        <div class="test-item__provider-name">
          {{ provider.name }}
        </div>
      </div>

      <slot name="mainBlock" />
    </div>

    <div v-if="$slots.additionalBlock" class="test-item__block">
      <slot name="additionalBlock" />
    </div>
  </div>
</template>

<script>
import { bem } from 'src/utils/bem.js';

import VisibilitySettings from 'src/components/tests/VisibilitySettings/VisibilitySettings.vue';

export default {
  name: 'TestItem',
  components: {
    VisibilitySettings,
  },
  inject: ['mediaQueries'],
  props: {
    hasVisibilitySettings: {
      type: Boolean,
      required: true,
    },
    isHidden: {
      type: Boolean,
      required: false,
    },
    provider: {
      type: Object,
      required: true,
    },
    appearance: {
      type: String,
      required: true,
      validator(value) {
        return ['passed', 'expired', 'failed', 'sent'].includes(value);
      },
    },
    statusName: {
      type: String,
      default: null,
    },
  },
  methods: {
    bem,
  },
};
</script>
